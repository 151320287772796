<template>
<div>
  <a-modal v-model="ringVisible" :title="caller" @ok="handleOk">
    <p>救援电话呼入，是否立即接单</p>
  </a-modal>
</div>
</template>

<script>
import "A/jquery-1.7.1.min.js"
import "A/agentuc.js"
import {getCache} from "U/index";

export default {
  name: "callCenter",
  data(){
    return{
      ringVisible:true,
      caller:'',
      opcall:'',
    }
  },
  created() {
    // this.init();
  },
  methods:{
    init(){
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        this.user=logininfo.phonename
        this.password=logininfo.phonepassword
        this.login_ext_no=logininfo.phonextnum
      }
    },
    handleOk(e) {
      this.ringVisible = false;
      console.log("测试")
      // this.$router.push({
      //   name:'emergency',
      //   params: {
      //   }
      // })
    },
    initPhone() {
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        this.user=logininfo.phonename
        this.password=logininfo.phonepassword
        this.login_ext_no=logininfo.phonextnum
      }
      //将IP和账号密码替换为实际的IP和账号、密码
      let cti_config = {
        debug: true,
        baseurl: "http://183.214.195.130:85/atstar/client.php",
        user: this.user,
        password: this.password,
        login_ext_no: this.login_ext_no
      };
      this.opcall = new call(cti_config);
      this.msg_div = $("#msg_div");
      this.stat_div = $("#stat_div");
      // this.bind_event(this.opcall);
      return this.opcall
    },
    // 坐席
    bind_event(opcall) {
      var that =this;
      opcall.lib.event("login").unsubscribe().subscribe(function () {
        $("#token").val(opcall.cfg.token);
        $("#login").attr("disabled", true);
        $("#logout").attr("disabled", false);
        $("#list_agent").attr("disabled", false)
        $("#list_queue").attr("disabled", false)
      });

      opcall.lib.event("logout").unsubscribe().subscribe(function () {
        console.log("坐席登出成功");
        // this.stat_div.html("坐席登出");
        $("#login").attr("disabled", false);
        $("#logout").attr("disabled", true);
        $("#hangup").attr("disabled", true);
        $("#hold").attr("disabled", true);
        $("#unhold").attr("disabled", true);
        $("#tranfer").attr("disabled", true);
        $("#monitor").attr("disabled", true);
        $("#breakin").attr("disabled", true);
        $("#breakin2threeway").attr("disabled", true);

        $("#status").attr("disabled", true);
        $("#checkout").attr("disabled", true);
        $("#checkin").attr("disabled", true);
        $("#dia").attr("disabled", true);
        $("#dia_num").attr("disabled", true);
        $("#switch_threeway").attr("disabled", true);
        $("#cancel_transfer").attr("disabled", true)
        $("#xfer2threeway").attr("disabled", true)
        $("#list_agent").attr("disabled", true)
        $("#list_queue").attr("disabled", true)
      });

      opcall.lib.event("login_fail").unsubscribe().subscribe(function (data) {
        console.log(data.message);
        // this.stat_div.html("离线");
      });

      opcall.lib.event("member_count").unsubscribe().subscribe(function (data) {
        $("#member_count").html(data);
      });

      opcall.lib.event("idle").unsubscribe().subscribe(function () {
        // this.stat_div.html("空闲");
        $("#dia").attr("disabled", false);
        $("#dia_num").attr("disabled", false);
        $("#hangup").attr("disabled", true);
        $("#answer").attr("disabled", true);
        $("#hold").attr("disabled", true);
        $("#unhold").attr("disabled", true);
        $("#tranfer").attr("disabled", true);
        $("#blind_transfer").attr("disabled", true);

        $("#cancel_transfer").attr("disabled", true)
        $("#xfer2threeway").attr("disabled", true)

        $("#tranfer_queue").attr("disabled", true);
        $("#monitor").attr("disabled", true);
        $("#spy").attr("disabled", false);
        $("#breakin").attr("disabled", false);
        $("#breakin2threeway").attr("disabled", false);
        $("#switch_threeway").attr("disabled", true);
      });

      // opcall.lib.event("ring").unsubscribe().subscribe(function (ext) {
      //   // console.log(ext)
      //   // console.log(ext.agent_state)
      //   if(ext.agent_state=='Receiving'){
      //     console.log(that.ringVisible)
      //     that.ringVisible=true;
      //     that.caller=ext.peer;
      //     that.$router.push({
      //       name:'emergency',
      //       params: {
      //         caller:ext.peer,
      //       }
      //     })
      //     // Bus.$emit('caller-value',ext.peer)
      //     // setTimeout(()=> {
      //     //   Bus.$emit('caller-value',ext.peer)
      //     // },2500)
      //   }
      //   // this.stat_div.html("振铃");
      //   $("#dia").attr("disabled", true);
      //   $("#dia_num").attr("disabled", true);
      //   $("#hangup").attr("disabled", false);
      //   $("#answer").attr("disabled", false);
      //   $("#spy").attr("disabled", true);
      //   $("#breakin").attr("disabled", true);
      //   $("#breakin2threeway").attr("disabled", true);
      //   $("#blind_transfer").attr("disabled", false);
      //
      //   console.log("电话振铃了[此处完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
      // });

      opcall.lib.event("busy").unsubscribe().subscribe(function (ext) {
        // this.stat_div.html("忙线");
        console.log("电话接通了[如果缺失ring事件，那么应该在answer中完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
        $("#dia").attr("disabled", true);
        $("#dia_num").attr("disabled", true);
        $("#hangup").attr("disabled", false);
        $("#answer").attr("disabled", true);
        $("#hold").attr("disabled", false);
        $("#unhold").attr("disabled", false);
        $("#tranfer").attr("disabled", false);
        $("#tranfer_queue").attr("disabled", false);
        $("#switch_threeway").attr("disabled", false);
        $("#cancel_transfer").attr("disabled", false)
        $("#xfer2threeway").attr("disabled", false)
        $("#blind_transfer").attr("disabled", false);
      });

      opcall.lib.event("xfer_busy").unsubscribe().subscribe(function (ext) {
        // this.stat_div.html("忙线");
        // console.log("电话转接已经接通，来电号码" + ext.xfer_source + "，您的号码：" + ext.ext_no + "，第三方号码:" + ext.xfer_to + "转接随路:" + ext.xfer_info);
      });

      opcall.lib.event("available").unsubscribe().subscribe(function (ext) {
        opcall.lib.log("receive available");
        if (opcall.cfg.state.call_state == 'idle') {
          // this.stat_div.html("空闲");
        }

        $("#status").attr("disabled", false);
        $("#checkout").attr("disabled", false);
        $("#checkin").attr("disabled", true);
      });
      /**
       * 处理坐席签出消息
       */
      opcall.lib.event("unavailable").unsubscribe().subscribe(function (ext) {
        opcall.lib.log("receive unavailable:" + ext.state_cause_description);
        if (opcall.cfg.state.call_state == 'idle') {
          var desc = ext.state_cause_description ? ext.state_cause_description : (ext.state_cause == "pause" ? "话后" : "小休")
          // this.stat_div.html(desc);
        }

        $("#status").attr("disabled", true);
        $("#checkout").attr("disabled", true);
        $("#checkin").attr("disabled", false);
      });

      /**
       * 处理坐席保持事件
       */
      opcall.lib.event("hold").unsubscribe().subscribe(function (ext) {
        opcall.lib.log("receive hold");
        // this.stat_div.html("电话保持");
        $("#hold").attr("disabled", false);
        $("#unhold").attr("disabled", false);
      });


      /**
       * 处理分机离线事件
       */
      opcall.lib.event("offline").unsubscribe().subscribe(function (ext) {
        opcall.lib.log("receive offline");
        // this.stat_div.html("分机离线");

        $("#hangup").attr("disabled", true);
        $("#hold").attr("disabled", true);
        $("#unhold").attr("disabled", true);
        $("#tranfer").attr("disabled", true);
        $("#monitor").attr("disabled", true);
        $("#breakin").attr("disabled", true);
        $("#status").attr("disabled", true);
        $("#checkout").attr("disabled", true);
        $("#checkin").attr("disabled", true);
        $("#dia").attr("disabled", true);
        $("#dia_num").attr("disabled", true);
        $("#tranfer_queue").attr("disabled", true);
        $("#cancel_transfer").attr("disabled", true)
        $("#xfer2threeway").attr("disabled", true)
      });
    },
    // 登录
    login() {
      try {
        this.isAble=false;
        this.phoneLoginVisible=false;
        this.initPhone();
        this.opcall.lib.startup();
      } catch (err) {
        this.isAble=true;
        this.phoneLoginVisible=true;
        alert(err);
      }
    },
    //登出
    logout() {
      this.phoneLoginVisible=true;
      this.isAble=true;
      this.opcall.lib.stop();
    },

    hold() {
      /**
       * 参数1：表示要保持的分机号，-表示当前登录的分机号
       * 参数2：是否要确认保持的提示文字，null表示不确认
       * 参数3：保持完成后的回调函数，在回调函数中，会回调一个data的Json对象，用于表示是否成功，
       *        他包含了以下属性：
       *      success:  是否成功，true表示成功，false表示失败，
       *      message： 失败原因
       *
       */
      this.opcall.cli.hold('-', null, function (data) {
        console.log(data.message);
      });
    },

    unhold() {
      this.opcall.cli.unhold('-', null, function (data) {
        console.log(data.message);
      });
    },

    hangup() {
      this.opcall.cli.hangup('-', null, function (data) {
        console.log(data.message);
      });
    },

    answer() {
      this.opcall.cli.answer('-', null, function (data) {
        //console.log(data.message);
      });
    },

    //咨询转接
    blind_transfer() {
      //46012是您要转接的号码
      var to = prompt("请输入目标号码：");
      if (to) {
        this.opcall.cli.blind_transfer('-', to, function (data) {
          console.log(data.message);
        });
      }
    },
    //咨询转接
    transfer() {
      //46012是您要转接的号码
      var to = prompt("请输入目标号码：");
      if (to) {
        this.opcall.cli.transfer('-', to, function (data) {
          console.log(data.message);
        });
      }
    },
    //咨询转转三方
    xfer2threeway() {
      this.opcall.cli.xfer2threeway(function (data) {
        console.log(data.message);
      });
    },
    //设置转接随路
    set_var() {
      var msg = prompt("请输入随路数据：");
      if (msg) {
        this.opcall.cli.set_var('-', 'xfer_info', msg, 'both', function (data) {
          console.log(data.message);
        });
      }
    },
    //取消咨询转
    cancel_transfer() {
      this.opcall.cli.cancelxfer(function (data) {
        console.log(data.message);
      });
    },
    //转接到队列
    transfer_queue() {
      //46012是您要转接的号码
      var to = prompt("请输入目标队列(格式为queue_技能组id@技能组名称)：");
      if (to) {
        this.opcall.cli.transfer_queue('-', to, function (data) {
          console.log(data.message);
        });
      }
    },
    //监听
    spy() {
      //46012是被监听者分机号码
      var to = prompt("请输入监听号码：");
      this.opcall.cli.spy(to, '-', function (data) {
        console.log(data.message);
      });
    },
    //强插
    breakin() {
      //46012是被监听者分机号码
      var to = prompt("请输入要强插的号码：");
      this.opcall.cli.breakin(to, '-', function (data) {
        console.log(data.message);
      });
    },
    //插话
    breakin2threeway() {
      //46012是被监听者分机号码
      var to = prompt("请输入要插话的号码：");
      this.opcall.cli.breakin2threeway('-', to, function (data) {
        console.log(data.message);
      });
    },

    switch_threeway(mod) {
      this.opcall.cli.switch_threeway('-', mod, function (data) {
        console.log(data.message);
      });
    },
    //拨号
    dial(num) {
      //46012是被监听者分机号码
      this.opcall.cli.dial('-', num, function (data) {
        // console.log(data.message);
      });
    },

    join_queue(queue) {
      //46012是被监听者分机号码
      this.opcall.cli.join_queue('-', queue, function (data) {
        // console.log(data.message);
      });
    },

    transfer_script(script_name) {
      //46012是被监听者分机号码
      var param = new Array("param1", "param2", "param3");
      if (script_name == "flycua_ivr_pay.lua") {
        param = new Array('http://3323232', 'appid', 'securcry');
      }
      this.opcall.cli.transfer_script('-', script_name, param, function (data) {
        console.log(data.message);
      });
    },
    //强插
    agent_state(status) {
      this.opcall.cli.adv_agent_status('-', status, '', function (data) {
        console.log(data.message);
      });
    },
    //获取坐席列表
    list_agent() {
      this.opcall.cli.list_agent(function (data) {
        console.log(JSON.stringify(data))
      });
    },
    //获取坐席列表
    list_queue() {
      this.opcall.cli.list_queue(function (data) {
        console.log(JSON.stringify(data))
      });
    },
  }
}
</script>

<style scoped>

</style>